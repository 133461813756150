import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { DetailsBlog, Blockquote } from "../../components/details.js";
import { NextPrevPosts } from "../../components/blogPost.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import arc from "../../images/blog/syde-1a/arc.png";
import crab from "../../images/blog/syde-1a/crab.png";
import gunship from "../../images/blog/syde-1a/gunship.png";
import knightbus from "../../images/blog/syde-1a/knightbus.png";
import scroll from "../../images/blog/syde-1a/scrollb.png";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { date: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            type
            date
            order
            readTime
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1>{props.pageContext.frontmatter.title}</h1>
    </div>
    <DetailsBlog date={props.pageContext.frontmatter.date} time={props.pageContext.frontmatter.readTime} mdxType="DetailsBlog" />
    <p>{`My first term of Systems Design Engineering (SYDE) at the University of Waterloo went by so quickly. It feels like just yesterday when I was moving into CMH, interviewing for my first co-op, finishing final exams, and now onto my first co-op.`}</p>
    <p>{`Throughout this, I'm going to go over my thoughts on the academics, co-op process, and just SYDE in general.`}</p>
    <h2>{`Academics`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/277c6/schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.22222222222223%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACb0lEQVQoz32TS09TURSFrz/Aqf4FHJr4mBmif8CJRk10aOKIiRKf0BAxBNCGRwxFyytojBUplEoEqtiBD1qgUqFc7ANKiRYo5dHee9vecz9zWxR04E5W9to5Z6+TnbO2JITAEAZKVkEvlLgwDFRVK+YihCCrKH/qgq6jairCEGDWhTyapgIGki50/huGYd4DsZv/PQZ0owSTS8nZFOP3JvHc8eGpmGB4eJqGhVHuB9xUTQ/xOuKjM+yhKeTG9t1Dl8/FA4+NSncjltEnJOUO9HAtWugmuagVSfYmaDjlovH0EPXH+rF3zXEz8IMK7xLXvItcfbvE4e4wB+0yh3oWueTop7yznrKWu5ywtzLvLkcZOkDaKbEzfhRpY0MhML/BzEKaqVCKrczeKGa8j+e5/Xmbal+GuqkszqjG81gee1ijIwLKz0FItsDqY0g7kJYT2/S9iTM4Eqf7VRw58BEjbiUXsaLJ1ZCPs/+J4FQStyuG27XIyHAUx9AathfrNHUn6XGuIa0FZb5a6pix1OK/XkNisA7lyxl2vOXsjB1BS/nJA7l8jgKwONHOnOs8QedZImOXqa1xceGKm3MX+6i89Q5pXY4x22wj1NbJjNXGpjxltgEyMAdkEEV3iNKHZxZg6wNsjYM6SWrAwXp7K6uPakn32pFC8gaW1hBVzbPceBhkbCDOt2dxJp7G+NQSJr2cLY4qdKMoKky+D2o0ihoMoAT8qPIcUiajkVjZZmVlm+Smgq9Xxna8n7aT/TSVvSThXy0JmqYvmlzH2IUQhb+sWfShuQX7Q+iCvFZAz+voBb1Yl4T2tsbMxm+u6xi7MPkvql0FNScHnmsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "1A Schedule",
            "title": "1A Schedule",
            "src": "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/1cfc2/schedule.png",
            "srcSet": ["/static/79fa0cfc55ff7aaef1ed410d07b46dd9/3684f/schedule.png 225w", "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/fc2a6/schedule.png 450w", "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/1cfc2/schedule.png 900w", "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/21482/schedule.png 1350w", "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/d61c2/schedule.png 1800w", "/static/79fa0cfc55ff7aaef1ed410d07b46dd9/277c6/schedule.png 2522w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  This is what our 1A schedule looked like. Our Wednesdays were too good to be
  true.
    </p>
    <p>{`Academically, I think that SYDE 1A was a good transition from high school to the different atmosphere of university. The SYDE professors and my classmates were always willing to answer questions or further explain topics. That being said, it wasn't always easy as I was adjusting to the new environment and a lot of new people at once. Below I've outlined a little bit more about each of the courses I took, my experiences, and any advice I have!`}</p>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">SYDE 101 + 101L (Communication)</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <p>
        These were some chill courses that kind of gave an overview of how to
        communicate as an engineer – whether it's spoken and written English, or
        visually through diagrams and such. Personally, I enjoyed 101L a lot. It
        felt like I was able to put my understanding of 3D space and design to
        the test.
      </p>
      <p>
        These two courses were worth only half a credit each and for the most
        part, the coursework reflected that much work. Although, I do remember
        staying at the E5 lab till late at night because I didn't have a Windows
        machine that can run Solidworks and finish creating my Arc de Triomphe
        model.
      </p>
      <p>
        In 101, we covered engineering communication in the form of primarily
        written communication. It was made up of weekly tutorials and a class
        seminar with guest lecturers. In the tutorials, we would read short
        texts and then write abstracts, executive summaries, or other analysis
        pieces. While it may see intimidating like high school english, it ended
        up being pretty simple and straightforward. One key thing I remember
        about this course was the chance to interact with profs on a more
        individual basis because our tutorials were composed of up to 30
        students. This meant that it felt like high school again where the prof
        might walk around and ask how we are doing.
      </p>
      <p>
        For 101L, we had Igor and it was one of those courses where I enjoyed
        what we were learning and doing. For this course, we often had labs
        where we worked in Solidworks and on paper in this textbook with grid
        lines and exercises. I enjoyed it since I got to draw, something that I
        missed doing in the little time I had. This was also my first time
        learning Solidworks and got me interested in the field of 3D design.
        While it was difficult at first, it ended up being fun towards the end.
      </p>
      <div className="row">
        <div className="column">
          <img src={arc} alt="Simple model of the Arc de Triomphe" />
          <img src={gunship} alt="Model of Star Wars Republic Gunship" />
        </div>
        <div className="column">
          <img src={knightbus} alt="Model of the Knight Bus from Harry Potter" />
          <img src={crab} alt="3D printed crab puzzle" />
        </div>
      </div>
      <p>
        I wish we had more courses like 101L where we got to use a tool relevant
        to our industry and create things with it. I'm looking forward to SYDE
        223 where we can do some more relevant coding, and SYDE 263 which is
        engineering prototyping.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">SYDE 111 (Calculus)</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <p>
        This course is basically first year engineering calculus. Note that if
        you have friends in other engineering majors, you will not be covering
        the same things in this first calculus course. Instead, get yourself
        some computer science friends since this course more closely aligns with
        their calculus course.
      </p>
      <p>
        In this course, we cover things like induction, derivatives, curve
        sketching, optimization, Taylor polynomials, and a few other topics.
        There were weekly quizzes and problem sets, as well as a midterm and
        final exam which made up most of our final mark.
      </p>
      <p>
        Some really good resources for this course were{" "}
        <a href="https://www.khanacademy.org" className="link" target="_blank" rel="noreferrer">
          Khan Academy
        </a>
        , <a href="https://www.youtube.com/channel/UCEWpbFLzoYGPfuWUMFPSaoA" className="link" target="_blank" rel="noreferrer">
          Organic Chemistry Tutor
        </a>, and <a href="https://tutorial.math.lamar.edu" className="link" target="_blank" rel="noreferrer">
          Paul's Math Notes
        </a>. I know these along with my classmates really helped me understand the
        content and pass the course.
      </p>
      <p>
        For me, this was a difficult course. I finished the grade 12 math
        courses in grade 11, so I had not done math in about a year by the time
        I started university. Luckily, a lot of my notes came in handy because
        my school happened to cover some of the concepts, but there were still a
        lot of new, unfamiliar topics.
      </p>
      <p>
        This was the course that really helped me come to realize that marks are
        not the end of the world in university, and that while I may have had
        high marks in high school, university is a different playing field.
      </p>
      <p>
        That being said, I'm not great at math, nor do I really enjoy it. If you
        like the subject then this is for you since it's a good introduction to
        university level math.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">SYDE 113 (Linear Algebra)</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel"> 
      <p>
        Some of the topics we covered in this course include coordinate systems, conic sections, vectors, planes, and matrices. This course had lectures and interactive tutorials with marked content. It was interesting because the lectures covered certain topics and then the interactive tutorials seemed to take that content in a different direction. However, the quizzes were fairly straightforward.
      </p>
      <p>
        I didn't really know what to expect coming into this course, but it was different from what I expected. While this was definitely one of the more confusing courses, I tended to spend less time on it, only trying to learn the content when there were quizzes. 
      </p>
      <p>
       Some advice I would give about this course is to treat it like the half credit it is. While it is daunting with all the new content and possibly confusing lectures, the material that is actually marked should not be too far out there.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">SYDE 121 (Intro to C++)</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel"> 
      <p>
        This course was an introduction to programming and C++. It covered primative data types and how to manipulate those. If you have a background in Java, C#, Python, or TypeScript, this course should be pretty easy to pick up. It consisted of numerous labs, weekly lectures, a midterm, and a final exam. 
      </p>
      <p>
        I didn't really spend a lot of time on this course to be honest because I already came from a software background and luckily, Java is pretty close to C++. I can see why the course is helpful to those who do not know any programming, but it's also a breeze for people who already know. 
      </p>
      <p>
       Some advice I would give about this course is to try and learn some programming before coming into SYDE. This course will not be enough to get you a software co-op right off the bat in 1A if you do not know any other programming languages because the core concepts of algorithms and data structures are not taught until 223 in 1B. Also, reach out to people if you get stuck. There are probably a lot of people in your class that are willing to help you out.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">SYDE 161 (Intro to Design)</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel"> 
    <div className="row">
      <div className="column">
    <p>
      This course goes over the engineering design process, including but not limited to researching, ideation, prototyping, and refining. This is one of the courses I was looking forward to since it's the design part of the systems design.
    </p>
    <p>
       I personally found it an okay course and similar to what I was expecting. I am familiar with the engineering design process, so the content and methods taught were not new, I found it interesting that we had a term long project but the design prompt for our term was quite difficult. We had to design an ideation toolkit that would help FYDP teams, so it was very meta since we had to ideate to create the project too.
    </p>
    <p> 
        To me, it was interesting that we had a final exam for this course since a lot of it was practical versus theoretical. I would have preferred it if we got more projects to apply what we had learned. 
      </p>
      <p> 
       Some advice for this course is to take the mark you get with a grain of salt especially if you're a designer. For me at the time, I thought my mark meant that I wouldn't make a good designer. However, that proved to not be the entire story as a mark is a mark and sometimes the marks in this course were a bit RNG.  
      </p>
      </div>
      <div className="column column-img">
        <img src={scroll} alt="Our project at the final showcase" />
        <p className="caption">
      We made a prototype of a scrollable whiteboard for our final project.
        </p>
      </div>
    </div>    
    </AccordionItemPanel>
  </AccordionItem>
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">SYDE 181 (Statics)</AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel"> 
      <p>
        Lastly, there was statics otherwise known as the physics that doesn't move. Grade 12 physics helped a bit with this course with understanding and overall consisted of a number of assignments, a midterm, and a final. 
       </p>
       <p> 
        Overall, I thought it was a fair course, and the TA's were helpful if you asked for help. The lectures were similar in structure to what I had in grade 12 where we often went through the theory very briefly and then applied it to several problems. The main thing I learned from this course was to set everything to 0 and then complete the calculation from there. This would solve nearly most of the problems. 
      </p>
       <p> 
        For this course, I would advise going to the lectures so that you can see more problems and thus be more prepared for the exams. I think it was just a bit unfortunate for me that the lectures for this course happened to be at 8:30am... haha. 
      </p>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <h2>{`Co-op`}</h2>
    <p>{`The co-op process was a whirlwind. In SYDE, we start our co-op search in 1A for the following term after entering university. This means that we've just barely started our university journey when starting to look for a job.`}</p>
    <p>{`Coming into university, I should have known it was going to be fast but I was not prepared for the very fast-paced co-op search that started my second week of school. I barely had a resume ready and hadn't practiced for interviews that much.`}</p>
    <p>{`If I had to do it again, I definitely would have started prepping during the summer, and taken the initiative to reach out to upper years to learn more about the process and do resume critiques beforehand.`}</p>
    <p>{`I'd also ensure that I'm applying for places that I can see myself working at, rather than mass applying to companies that I think might interview me. This would reduce the stress that I ended up getting throughout the process. I know in the future, I'll be revising the way I approach the co-op search to better use my time and tailor my interests.`}</p>
    <p>{`This time around, I applied to a pretty even split between design and software jobs. One of the companies I interviewed with was the Ontario Digital Service which seemed very interesting after attending the information session. In the end, I'm really happy that I got the opportunity to intern here as a product designer!`}</p>
    <h2>{`Overall Thoughts`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eff9d062e3f534cd905087af2a0b3a35/493ac/peeps.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.11111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEQUlEQVQ4yx3M7VPTBQDA8d8rxBBlAZ0aTZJ0OB0eCKiokZ4GNoKUgbAxHuRBkcHmeJwgyLMgD8dgykMSjIGITnQgB4kTNVD0ylOD0FMrH9Kr67yy8k133+76/AEfoaUiE2N5Fk1lWowVOowVeoyV2Rircqgt1lCSk0DegWhyMmLJ16rJ0ajI08ZTlJvMIX0ihoPxGHQx5GXuJjMlDKG6MJ32hny+aiqiq7kYs6kEy4kyetsqaK3Lx5CpQJcaQW6mmoLsFA7lp5OvTyM7KwXNfjUpSZHEK0NJUoeQmhSBsFn2IbWluQz0tGEfPc/V8SHGR85yedTK8KCFxvoKGuoqOFZTTHzMTjYFSNmXpiZXv5+D2mTS0xNRxyvRH0yhpjwTIe7jtZztP8PdmWect13iwrCdm9/NMXnrPtdv3OHK1F0m7zxhZPRrSpLCUQRKqDV2YxudpNtsobOrj/7+TjpO5NN0zICw0sOVlsYahofH2Be+hbSQddRVH8ZmtXBuwIx1wIxtsI/RIQu9JWlodqzDaGzmy84u2lqP09XdRfmRAuQ7fNFp4hDmz3egsCAHc7uRpr2h5MrXk5W6B1NDGW0ttZzuMWEbaGXsopnx0400ZikoLcwgLVlJQ/1RyivLyZAHoAoQ4+MtRnBa4IgqNpoylZLD4YEcCAtiv1pBqUHLyRN1ZGft5WTLEV48usbPc3Z+e2TH1ttIX3sF10ctDA100KaNJDtkNeHbfBFEi5xI9vNnl48fUlcREnc3gn1kFOpSOdtjQrErlOaaPPhnljfPp/jr+RRvX93m7S/T/Ptqmpezl9DE7iTCz4M1Uk+EwPeXovD0wtvNnS0rVxDsI8VL5EJi1OecP9XK3j1yjhZn8Pezb3g8beXpnQu8mBnj5QM7b57e4NuJAfylH+AnWULYpxsQtnqICXB1R+axlLCN/gTLViFetJDggLV0Hj+KKnInh7OTeP3oMvcnepmb6ufxzVM8mDzFy+9HmDRXssbzPYICvSnNjkOI/MgbXzc3PhK5IHFxwcvZGXfHeayTLKe+qoDY6HAMugR+nRvj7pUefpjs5+GUhSfT/fz54wSXuyrxly5j20YJWzZIESK9JHgvcGaxoyOr3hWxQuTyf+izzIOqEh0xUXL06Ur+eGzn3kQPM9d6mb3Wx5NbVl4/HMd+zkSBJprKQ/vISI5E2O6+hN3+MoJWL8fTyQmJyAVP0SL8losp0ieSmhBBzBdbaanW0XYshzMd5Vw918Ls1V5+uj2IrbuGvAwFnUYDg901CFZTEb/PDDBy0oBMvBiRwzwitq9HHbKRakMSJXolmiQ58VGfsHWzDFdXZwJ8JSREh9JRl4epSkuJXsWwuRK7tRnh9kgDQ+253LtYz5FMBQ6ODqQpP6OjLJ32Wh31xSmU5ago1EaRqtqB88L5vOM0D/fFbgRvkiIPWonMW0xKnByDNp7/AEefxQ8a9O0MAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Some friends",
            "title": "Some friends",
            "src": "/static/eff9d062e3f534cd905087af2a0b3a35/1cfc2/peeps.png",
            "srcSet": ["/static/eff9d062e3f534cd905087af2a0b3a35/3684f/peeps.png 225w", "/static/eff9d062e3f534cd905087af2a0b3a35/fc2a6/peeps.png 450w", "/static/eff9d062e3f534cd905087af2a0b3a35/1cfc2/peeps.png 900w", "/static/eff9d062e3f534cd905087af2a0b3a35/21482/peeps.png 1350w", "/static/eff9d062e3f534cd905087af2a0b3a35/d61c2/peeps.png 1800w", "/static/eff9d062e3f534cd905087af2a0b3a35/493ac/peeps.png 4032w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`My 1A term of SYDE was a lot of fun! I think I really learned a lot and got to experience a lot of things including finding my first co-op job, waking up at 4am for fire alarms, and struggling in university-level math. I'm looking forward to 1B and whatever is to come!`}</p>
    <h4>{`More posts`}</h4>
    <NextPrevPosts projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="NextPrevPosts" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      